<template>
    <div
        class="px-4 w-full dF fC f1 pb-4 hide-scrollbar"
        style="overflow-y: scroll"
		v-if="selectedPartner"
    >
        <div
            class="mb-3 dF aC"
            style="font-size: 24px; gap: 20px; justify-content: space-between"
        >
            <div>
                <a-icon type="arrow-left" @click="$router.go(-1)" />
                <strong class="ml-3">{{ selectedPartner.companyName }}</strong>
            </div>
        </div>
        <div>
            <a-tabs
                size="large"
                default-active-key="overview"
                v-model="activeTab"
            >
                <a-tab-pane key="overview" tab="Overview">
                    <div class="profile mt-2">
                        <div :class="$mq == 'md' ? '' : 'dF'" style="gap: 20px">
                            <div
                                :class="$mq == 'md' ? 'mt-3' : ''"
                                :style="
                                    $mq == 'md' ? 'width:100%' : 'width:70%'
                                "
                            >
                                <a-card>
                                    <h6 class="mb-4 text-dark">
                                        <strong>Partner Details</strong>
                                    </h6>
                                    <hr
                                        style="
                                            margin-left: -24px;
                                            margin-right: -24px;
                                        "
                                    />

                                    <a-form-model
                                        ref="ruleForm"
                                        :model="partner"
                                        :rules="rules"
                                    >
                                        <a-row :gutter="16">
                                            <a-col :span="$mq == 'sm' ? 24 : 8">
                                                <a-form-model-item
                                                    ref="partnerID"
                                                    label="Partner ID"
                                                    prop="partnerID"
                                                >
                                                    <a-input
                                                        v-model="
                                                            selectedPartner.readableId
                                                        "
                                                        placeholder="Partner ID"
                                                        size="large"
                                                        disabled
                                                    >
                                                    </a-input>
                                                </a-form-model-item>
                                            </a-col>
                                            <a-col :span="$mq == 'sm' ? 24 : 8">
                                                <a-form-model-item
                                                    prop="companyName"
                                                    label="Legal Company Name"
                                                    required
                                                >
                                                    <a-input
                                                        v-model="
                                                            partner.companyName
                                                        "
                                                        placeholder="Legal Company Name"
                                                        size="large"
                                                    >
                                                    </a-input>
                                                </a-form-model-item>
                                            </a-col>
                                            <a-col :span="$mq == 'sm' ? 24 : 8">
                                                <a-form-model-item
                                                    prop="industry"
                                                    label="Business Type"
                                                    required
                                                >
                                                    <a-select
                                                        v-model="
                                                            partner.industry
                                                        "
                                                        size="large"
                                                    >
                                                        <a-select-option
                                                            value="Builder"
                                                        >
                                                            Builder
                                                        </a-select-option>
                                                        <a-select-option
                                                            value="Real Estate Broker"
                                                        >
                                                            Real Estate Broker
                                                        </a-select-option>
                                                        <a-select-option
                                                            value="Advertising Agency"
                                                        >
                                                            Advertising Agency
                                                        </a-select-option>
                                                        <a-select-option
                                                            value="Freelancer"
                                                        >
                                                            Freelancer
                                                        </a-select-option>
                                                        <a-select-option
                                                            value="Other"
                                                        >
                                                            Other
                                                        </a-select-option>
                                                    </a-select>
                                                </a-form-model-item>
                                            </a-col>
                                        </a-row>
                                        <a-row :gutter="16">
                                            <a-col :span="24">
                                                <a-form-model-item
                                                    prop="users"
                                                    label="Users"
                                                >
													<a-select
														mode="multiple"
														option-label-prop="label"
														placeholder="Choose a user(s)"
														v-model="partner.users"
														style="width: 100%"
														:filterOption="false"
														@search="handleUserSearch"
														size="large"
													>
														<a-select-option
															v-for="user in userSearchResult"
															:key="user.value"
															:label="user.label"
															:value="user.value"
														>
															<div class="dF aC">
																<div class="f1">
																	<div
																		style="
																			color: black;
																			font-size: 15px;
																		"
																	>
																		{{ user.label }} ({{ user.email }})
																	</div>
																</div>
															</div>
														</a-select-option>
													</a-select>
                                                </a-form-model-item>
                                            </a-col>
                                        </a-row>
                                        <a-row :gutter="16">
                                            <a-col
                                                :span="$mq == 'sm' ? 24 : 16"
                                            >
                                                <a-form-model-item
                                                    prop="address"
                                                    label="Address"
                                                    required
                                                >
                                                    <a-input
                                                        class="
                                                            input-border-style
                                                        "
                                                        size="large"
                                                        v-model="
                                                            partner.address
                                                        "
                                                    />
                                                </a-form-model-item>
                                            </a-col>
                                            <a-col :span="$mq == 'sm' ? 24 : 8">
                                                <a-form-model-item
                                                    prop="country"
                                                    label="Country"
                                                    required
                                                >
                                                    <a-select
                                                        show-search
                                                        size="large"
                                                        class="dropdown-style"
                                                        v-model="
                                                            partner.country
                                                        "
                                                        @change="
                                                            partner.region =
                                                                null;
                                                            partner.postal =
                                                                null;
                                                        "
                                                    >
                                                        <a-select-option
                                                            v-for="country in countries"
                                                            :key="country.value"
                                                            :value="
                                                                country.name
                                                            "
                                                        >
                                                            {{ country.name }}
                                                        </a-select-option>
                                                    </a-select>
                                                </a-form-model-item>
                                            </a-col>
                                        </a-row>
                                        <a-row :gutter="16">
                                            <a-col :span="$mq == 'sm' ? 24 : 8">
                                                <a-form-model-item
                                                    prop="city"
                                                    label="City"
                                                    required
                                                >
                                                    <a-input
                                                        placeholder="City"
                                                        size="large"
                                                        v-model="partner.city"
                                                    />
                                                </a-form-model-item>
                                            </a-col>
                                            <a-col :span="$mq == 'sm' ? 24 : 8">
                                                <a-form-model-item
                                                    prop="region"
                                                    label="State/Province"
                                                    required
                                                >
                                                    <a-input
                                                        placeholder="State/Province"
                                                        size="large"
                                                        v-model="partner.region"
                                                    />
                                                </a-form-model-item>
                                            </a-col>
                                            <a-col :span="$mq == 'sm' ? 24 : 8">
                                                <a-form-model-item
                                                    prop="postal"
                                                    label="Zip/Postal Code"
                                                    required
                                                >
                                                    <a-input
                                                        placeholder="Zip/Postal Code"
                                                        size="large"
                                                        v-model="partner.postal"
                                                    />
                                                </a-form-model-item>
                                            </a-col>
                                        </a-row>
                                        <a-row :gutter="16">
                                            <a-col :span="$mq == 'sm' ? 24 : 8">
                                                <a-form-model-item
                                                    ref="contactFirstName"
                                                    label="First Name"
                                                    prop="contactFirstName"
                                                >
                                                    <a-input
                                                        v-model="
                                                            partner.contactFirstName
                                                        "
                                                        placeholder="First Name"
                                                        size="large"
                                                        required
                                                    >
                                                    </a-input>
                                                </a-form-model-item>
                                            </a-col>
                                            <a-col :span="$mq == 'sm' ? 24 : 8">
                                                <a-form-model-item
                                                    ref="contactLastName"
                                                    label="Last Name"
                                                    prop="contactLastName"
                                                >
                                                    <a-input
                                                        v-model="
                                                            partner.contactLastName
                                                        "
                                                        placeholder="Last Name"
                                                        size="large"
                                                        required
                                                    >
                                                    </a-input>
                                                </a-form-model-item>
                                            </a-col>
                                            <a-col :span="$mq == 'sm' ? 24 : 8">
                                                <a-form-model-item
                                                    prop="contactRole"
                                                    label="Your Role"
                                                    required
                                                >
                                                    <a-input
                                                        class="
                                                            input-border-style
                                                        "
                                                        size="large"
                                                        v-model="
                                                            partner.contactRole
                                                        "
                                                    />
                                                </a-form-model-item>
                                            </a-col>
                                        </a-row>
                                        <a-row :gutter="16">
                                            <a-col
                                                :span="$mq == 'sm' ? 24 : 12"
                                            >
                                                <a-form-model-item
                                                    ref="email"
                                                    label="Email Address"
                                                    prop="email"
                                                >
                                                    <a-input
                                                        v-model="partner.email"
                                                        placeholder="Email Address"
                                                        size="large"
                                                        required
                                                    >
                                                    </a-input>
                                                </a-form-model-item>
                                            </a-col>
                                            <a-col
                                                :span="$mq == 'sm' ? 24 : 12"
                                            >
                                                <a-form-model-item
                                                    prop="phone"
                                                    label="Phone Number"
                                                    required
                                                >
                                                    <a-input
                                                        v-model="partner.phone"
                                                        placeholder="Phone Number"
                                                        size="large"
                                                        type="number"
                                                    >
                                                    </a-input>
                                                </a-form-model-item>
                                            </a-col>
                                        </a-row>
                                    </a-form-model>

                                    <div class="dF jE w-full" style="gap: 20px">
                                        <a-button @click="$router.go(-1)"
                                            >CANCEL</a-button
                                        >
                                        <a-button
                                            type="primary"
                                            @click="updatePartner"
                                            >SAVE</a-button
                                        >
                                    </div>
                                </a-card>
                            </div>
                            <div
                                :style="
                                    $mq == 'md' ? 'width:100%' : 'width:30%'
                                "
                            >
                                <a-card style="max-height: 150px">
                                    <div
                                        class="dF aC"
                                        style="
                                            gap: 10px;
                                            justify-content: space-between;
                                            font-size: 18px;
                                        "
                                    >
                                        <h5>Company Administrators</h5>
                                        <a
                                            @click.prevent="viewAll"
                                            href="javascript:;"
                                            class="text-primary"
                                            >View All</a
                                        >
                                    </div>
                                </a-card>
                                <a-table
                                    :rowKey="(e) => e.id"
                                    :columns="userColumns"
                                    :dataSource="users"
                                    :pagination="false"
                                    :loading="loading"
                                    class="list-view"
                                >
                                    <div slot="name" slot-scope="user">
                                        <span>{{
                                            user.firstName + " " + user.lastName
                                        }}</span>
                                    </div>
                                    <div slot="role" slot-scope="user">
                                        <span>{{ user.role }}</span>
                                    </div>
                                    <div
                                        slot="actions"
                                        slot-scope="user"
                                        class="dF"
                                        style="justify-content: flex-end"
                                    >
                                        <a-popover
                                            trigger="hover"
                                            placement="bottomRight"
                                            overlayClassName="popoverStyle"
                                        >
                                            <div slot="content">
                                                <div
                                                    class="popoverContent dF aC"
                                                >
                                                    Edit User
                                                </div>
                                            </div>
                                            <div class="more-option-icon mr-4">
                                                <a-icon
                                                    style="line-height: 40px"
                                                    type="more"
                                                />
                                            </div>
                                        </a-popover>
                                    </div>
                                </a-table>
                            </div>
                        </div>
                    </div>
                </a-tab-pane>
                <a-tab-pane key="projects" tab="List of projects">
                    <a-table
                        :rowKey="(e) => e.key"
                        :columns="projectsColumns"
                        :dataSource="projects"
                        :loading="loading"
                        class="list-view"
                    >
                        <div slot="readableId" slot-scope="instance">
                            {{ instance.readableId }}
                        </div>
                        <div slot="name" slot-scope="instance">
                            {{ instance.name }}
                        </div>
                        <div slot="projectType" slot-scope="instance">
                            {{ instance.projectType }}
                        </div>

                        <div slot="productType" slot-scope="instance">
                            {{
                                instance.productType === "lowrise"
                                    ? "Low Rise"
                                    : "High Rise"
                            }}
                        </div>
                        <div slot="owner" slot-scope="instance">
                            <span v-if="instance.owner">{{
                                instance.owner.firstName +
                                " " +
                                instance.owner.lastName
                            }}</span>
                        </div>
                        <div slot="planType" slot-scope="instance">
                            {{ instance.package && instance.package.name }}
                        </div>
                        <div
                            slot="createdAt"
                            slot-scope="instance"
                            style="color: var(--med-gray)"
                        >
                            {{ getDate(instance.createdAt) }}
                        </div>
                        <div slot="status" slot-scope="instance">
                            <span v-if="instance.deleted">Deleted</span>
                            <span v-else-if="instance.susspended">
                                Locked
                            </span>
                            <span v-else> Active</span>
                        </div>
                        <div
                            slot="actions"
                            slot-scope="instance"
                            class="dF"
                            style="justify-content: flex-end"
                        >
                            <div
                                @click="editProject(instance)"
                                class="popoverContent"
                            >
								<a-icon type="edit" />
                            </div>
                        </div>
                    </a-table>
                </a-tab-pane>
            </a-tabs>
        </div>
    </div>
</template>

<script>
import { getInitial } from "bh-mod";
import countryData from "@/staticData/countryData";
import postalCodes from "postal-codes-js";
import { mapMutations } from "vuex";
import moment from "moment";

export default {
    components: { },
    data: () => {
        return {
            loading: false,
            activeTab: "overview",
            countries: countryData.countryList,
            partner: {
                owner: "",
                contactFirstName: "",
                contactLastName: "",
                email: "",
                phone: "",
                companyName: "",
                industry: "",
                contactRole: "",
                address: "",
                country: "",
                region: "",
                city: "",
                postal: "",
                users: [],
            },
			userSearchResult: [],
            userColumns: [
                {
                    title: "Full Name",
                    key: "name",
                    scopedSlots: { customRender: "name" },
                    sorter: (a, b) =>
                        a.firstName + a.lastName < b.firstName + b.lastName
                            ? -1
                            : a.firstName + a.lastName >
                              b.firstName + b.lastName
                            ? 1
                            : 0,
                    sortDirections: ["descend", "ascend"],
                },
                {
                    title: "Role",
                    key: "role",
                    scopedSlots: { customRender: "role" },
                    sorter: (a, b) =>
                        a.role < b.role ? -1 : a.role > b.role ? 1 : 0,
                    sortDirections: ["descend", "ascend"],
                },
                {
                    title: "",
                    key: "actions",
                    scopedSlots: { customRender: "actions" },
                    width: "10%",
                },
            ],
            projectsColumns: [
                {
                    title: "Project ID",
                    key: "readableId",
                    scopedSlots: { customRender: "readableId" },
                    sorter: (a, b) =>
                        a.readableId < b.readableId
                            ? -1
                            : a.readableId > b.readableId
                            ? 1
                            : 0,
                },
                {
                    title: "Project Name",
                    key: "name",
                    scopedSlots: { customRender: "name" },
                    sorter: (a, b) =>
                        a.name < b.name ? -1 : a.name > b.name ? 1 : 0,
                },
                {
                    title: "Project Type",
                    key: "projectType",
                    scopedSlots: { customRender: "projectType" },
                    sorter: (a, b) =>
                        a.projectType < b.projectType
                            ? -1
                            : a.projectType > b.projectType
                            ? 1
                            : 0,
                },
                {
                    title: "Product Type",
                    key: "productType",
                    scopedSlots: { customRender: "productType" },
                    sorter: (a, b) =>
                        a.productType < b.productType
                            ? -1
                            : a.productType > b.productType
                            ? 1
                            : 0,
                },
                {
                    title: "Owner",
                    key: "owner.firstName",
                    scopedSlots: { customRender: "owner" },
                    sorter: (a, b) => {
                        const fullNameA =
                            (a.owner && a.owner.firstName + a.owner.lastName) ||
                            "";
                        const fullNameB =
                            (b.owner && b.owner.firstName + b.owner.lastName) ||
                            "";
                        return fullNameA < fullNameB
                            ? -1
                            : fullNameA > fullNameB
                            ? 1
                            : 0;
                    },
                },
                {
                    title: "Plan Type",
                    key: "package.name",
                    scopedSlots: { customRender: "planType" },
                    sorter: (a, b) =>
                        a.package &&
                        a.package.name < b.package &&
                        b.package.name
                            ? -1
                            : a.package &&
                              a.package.name > b.package &&
                              b.package.name
                            ? 1
                            : 0,
                },
                {
                    title: "Date Created",
                    key: "createdAt",
                    scopedSlots: { customRender: "createdAt" },
                    sorter: (a, b) =>
                        moment(a.createdAt).format("x") -
                        moment(b.createdAt).format("x"),
                },
                {
                    title: "Status",
                    key: "susspended",
                    scopedSlots: { customRender: "status" },
                    sorter: (a, b) =>
                        a.deleted < b.deleted
                            ? -1
                            : a.deleted > b.deleted
                            ? 1
                            : 0,
                },
                {
                    title: "",
                    key: "actions",
                    scopedSlots: { customRender: "actions" },
                    width: "10%",
                },
            ],
            projects: [],
        };
    },
    computed: {
        selectedPartner() {
            return this.$store.state.selectedPartner;
        },

        allUsers() {
            return this.$store.state.allUsers;
        },

        rules() {
            return {
                postal: [
                    { validator: this.validatePostalCodes, trigger: "change" },
                ],
                contactFirstName: [
                    {
                        required: true,
                        message: "Please enter your first name",
                        trigger: "change",
                    },
                ],
                contactLastName: [
                    {
                        required: true,
                        message: "Please enter your last name",
                        trigger: "change",
                    },
                ],
                contactRole: [
                    {
                        required: true,
                        message: "Please enter your role",
                        trigger: "change",
                    },
                ],
                phone: [
                    {
                        required: true,
                        message: "Please enter your business number",
                        trigger: "change",
                    },
                ],
                companyName: [
                    {
                        required: true,
                        message: "Please enter your companyName",
                        trigger: "change",
                    },
                ],
                owner: [
                    {
                        required: true,
                        message: "Please select the owner",
                        trigger: "change",
                    },
                ],
                industry: [
                    {
                        required: true,
                        message: "Please select your business type",
                        trigger: "change",
                    },
                ],
                address: [
                    {
                        required: true,
                        message: "Please enter your address",
                        trigger: "change",
                    },
                ],
                country: [
                    {
                        required: true,
                        message: "Please select your country",
                        trigger: "change",
                    },
                ],
                region: [
                    {
                        required: true,
                        message: "Please enter your region",
                        trigger: "change",
                    },
                ],
            };
        },

        users() {
            return this.selectedPartner.users.map((x) => {
                return {
                    ...x,
                    role:
                        x.id === this.selectedPartner.owner.id
                            ? "Owner"
                            : "Admin",
                };
            });
        },
    },
    created() {
		if(!this.selectedPartner){
			return this.$router.push('/');
		}
        this.partner = {
            owner: this.selectedPartner.owner,
            contactFirstName: this.selectedPartner.contactFirstName,
            contactLastName: this.selectedPartner.contactLastName,
            email: this.selectedPartner.email,
            phone: this.selectedPartner.phone,
            companyName: this.selectedPartner.companyName,
            industry: this.selectedPartner.industry,
            contactRole: this.selectedPartner.contactRole,
            address: this.selectedPartner.address,
            country: this.selectedPartner.country,
            region: this.selectedPartner.region,
            city: this.selectedPartner.city,
            postal: this.selectedPartner.postal,
            users: this.selectedPartner.users.map((u) => u.id),
        };
		this.userSearchResult = this.selectedPartner.users.map((u) => ({
			label: u.firstName + " " + u.lastName,
			value: u.id,
			email: u.email,
		}));
        this.getProjects();
    },
    methods: {
        ...mapMutations(["SET_PARTNER", "SET_PROJECT"]),

        getInitial,

		handleUserSearch(input){
			console.log('Handle Search');
			if (input.length < 3) {
				return (this.timerId = undefined);
			}
			const makeAPICall = () => {
				this.updateUserList(input);
			};

			if (this.timerId) clearTimeout(this.timerId);

			this.timerId = setTimeout(function () {
				makeAPICall();
				this.timerId = undefined;
			}, 400);
		},
		async updateUserList(input) {
			try {
				let { data } = await this.$api.get(
					`/super-admin/users?_q=${input}&limited=true`
				);
				this.userSearchResult = data.map(({ name, id, email }) => ({
					label: name,
					value: id,
					email: email,
				}));
			} catch (err) {
				if (err?.response?.status !== 400) {
					this.$message.error(this.$err(err))
				}
			}
        },

        async getProjects() {
            this.loading = true;
            try {
                let { data } = await this.$api.get(
                    `/super-admin/instances?_limit=-1&partner_eq=${this.selectedPartner.id}`
                );
                this.projects = data.map((x, i) => {
                    const project = {
                        ...x,
                        allChild: x.children,
                        key: i,
                    };
                    delete project.children;
                    return project;
                });
            } catch (err) {
				if (err?.response?.status !== 400) {
					this.$message.error(this.$err(err, "Error while fetching project list. Please try again."))
				}
            }
            this.loading = false;
        },

        updatePartner() {
            this.$refs.ruleForm.validate((valid) => {
                if (valid) {
                    this.loading = true;
                    this.$api
                        .put(`/partners/${this.selectedPartner.id}`, {
                            ...this.partner,
                        })
                        .then(({ data }) => {
                            this.loading = false;

                            this.$message.success(
                                "Successfully updated partner details"
                            );
                            this.SET_PARTNER(data);
                        })
                        .catch((err) => {
                            this.loading = false;
							if (err?.response?.status !== 400) {
								this.$message.error(this.$err(err, "Error while updating partner details. Please try again!"));
							}
                        });
                } else {
                    console.error("Invalid form details");
                    return false;
                }
            });
        },

        filterOption(input, option) {
            return (
                option.componentOptions.children[0].text
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
            );
        },

        validatePostalCodes(rule, value, callback) {
            if (value === "") {
                callback(new Error("Please enter a Zip/Postal Code"));
            } else {
                if (!this.partner.country) {
                    callback(new Error("Please select a country first"));
                }
                const countryCode = countryData.countries[this.partner.country];
                const result = postalCodes.validate(countryCode, value);
                if (result === true) {
                    callback();
                } else {
                    callback(new Error(result));
                }
            }
        },

        viewAll() {},

        getDate(date) {
            return moment(date).format("D/M/YYYY");
        },

        editProject(instance) {
            this.SET_PROJECT(instance);
            this.$router.push(`/projects/${instance.id}`);
        },
    },
};
</script>

<style lang="scss">
.profile-card {
    text-align: center;
}

.list-view {
    background-color: #fff;
    border-radius: 10px;
    box-shadow: #e1dede 1px 1px 5px;
    border: none;
}

.popoverContent {
    height: max-content;
    line-height: 30px;
    padding: 0 10px;
}
.popoverContent:hover {
    background-color: var(--off-white-light);
    color: var(--primary);
    cursor: pointer;
}

.hide-scrollbar::-webkit-scrollbar {
    display: none;
}
</style>
